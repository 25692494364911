// for doing something different from centene, without breaking centene
// or having some crazy long standing branch.
export default {
  // raggedLayout: true,
  // smallTiles: false,
  layout: "Channel Loyalty"
}

//Consumer Loyalty vs Channel Loyalty for layout difference
//The above section is for changing the layout for the main page's head section
//only option other than blank "" is expanded. 
